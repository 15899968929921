import { Script } from "gatsby-script"
import React, { Suspense } from "react"

export const LazyScript = props => {
  const isSSR = typeof window === "undefined"

  const Helmet = React.lazy(() => import("react-helmet"))

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <Helmet>
            <script>{`console.log('hello')`}</script>
          </Helmet>
        </Suspense>
      )}
    </>
  )
}
