import React from "react"
import checkNesting from "../../checkNesting"
import { NavDropdown } from "react-bootstrap"

export default function I18DropDown(props) {
  const { phrase, background } = JSON.parse(props.object.jsonData)

  return (
    <NavDropdown
      background={background}
      title={phrase}
      id={`primeagile__${props.object.uiObjectRelationId}__${props.object.uiObjectId}`}
    >
      {props.object.children && checkNesting(props.object.children)}
    </NavDropdown>
  )
}