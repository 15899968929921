import React from "react"
import checkNesting from "../checkNesting"
import useInlineStyle from "../UseInlineStyle"
import { Helmet } from "react-helmet"
import { LazyScript } from "./LazyScript"

export default function CustomContainer(props) {
  const { children, jsonData, uiObjectRelationId, objectName, uiObjectId } =
    props.object
  const { as, className, inlineStyle } = JSON.parse(jsonData)
  const { styleObject } = useInlineStyle(inlineStyle)
  let styles
  if (String(inlineStyle).trim() !== "{}") {
    try {
      styles = JSON.parse(inlineStyle)
    } catch (error) {}
  }

  const url = typeof window !== "undefined" ? window.location.href : ""

  switch (as) {
    case "span":
      return (
        <span
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          styles={styleObject}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </span>
      )
    case "div":
      return (
        <div
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={styleObject}
          data-primeagile-object={objectName}
        >
          {"/" === props.pageData.url && <LazyScript />}

          {checkNesting(children, props.page)}
        </div>
      )
    case "section":
      return (
        <section
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={styleObject}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </section>
      )
    case "article":
      return (
        <article
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={styleObject}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </article>
      )
    case "aside":
      return (
        <aside
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={styleObject}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </aside>
      )
    case "p":
      return (
        <p
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={styleObject}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </p>
      )
    default:
      return (
        <div
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          className={className}
          style={styleObject}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </div>
      )
  }
}
