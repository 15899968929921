import React, { Suspense, useState } from "react"
// import { Stepper, StepLabel, Step } from "@mui/material"
import styled from "styled-components"
import checkNesting from "../../checkNesting"
const { Stepper, StepLabel, Step } = React.lazy(() =>
  import("@mui/material").then(module => {
    return { default: module }
  })
)

// const StepLabel = React.lazy(() =>
//   import("@mui/material").then(module => {
//     return { default: module.StepLabel }
//   })
// )

// const Step = React.lazy(() =>
//   import("@mui/material").then(module => {
//     return { default: module.Step }
//   })
// )
const StepperDiv = styled.div`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  background: ${props => props.background};
  height: ${props => (props.height ? props.height : "100%")};
  width: ${props => (props.width ? props.width : "100%")};
`

const StepperContent = styled.div`
  display: flex;
  justify-content: ${props =>
    props.position === "center"
      ? "center"
      : props.position === "right"
      ? "end"
      : props.position === "left" && "start"}; ;
`

const StepperLabelDiv = styled.div`
  display: flex;
  justify-content: center;
`

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  transform: ${props => (props.flipButtons ? "rotateY(180deg)" : "")};
  justify-content: ${props =>
    props.position === "center"
      ? "center"
      : props.position === "right"
      ? "end"
      : props.position === "space-between"
      ? "space-between"
      : props.position === "space-around"
      ? "space-around"
      : props.position === "left" && "start"};
`

const NextButton = styled.button`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  color: ${props => props.color};
  background: ${props => props.background};
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: ${props => props.borderRadius};
  transform: ${props => (props.flipButtons ? "rotateY(-180deg)" : "")}; ;
`

const StepperComponent = props => {
  const { jsonData, children } = props.props.object
  const {
    className,
    id,
    padding,
    flipButtons,
    margin,
    background,
    buttonPosition,
    buttonClassName,
    buttonMargin,
    buttonPadding,
    buttonWidth,
    buttonHeight,
    buttonBorderRadius,
    buttonBackground,
    buttonTextColor,
    StepperContentPosition,
    labels,
    stepperWidth,
    stepperHeight,
    previousButton,
  } = JSON.parse(jsonData)
  // [stepperLabels, ]

  const [step, setStep] = useState(0)
  const stepDisplay = step

  // const dispatch = useDispatch();
  let childUse
  if (children) {
    childUse = children[step]
  }

  const stepperLabels = labels

  const previousButtonState = previousButton === "yes" ? true : false
  const [buttonPrevious] = useState(previousButtonState)

  return (
    <>
      <Suspense fallback={<div />}>
        <StepperDiv
          className={className}
          id={id}
          margin={margin}
          padding={padding}
          background={background}
          width={stepperWidth}
          height={stepperHeight}
        >
          <>
            <StepperLabelDiv>
              <Stepper sx={{ width: 900 }} alternativeLabel activeStep={step}>
                {stepperLabels?.map(stepperLabel => (
                  <Step key={stepperLabel}>
                    <StepLabel>{stepperLabel}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </StepperLabelDiv>

            {step === stepDisplay && (
              <StepperContent position={StepperContentPosition}>
                {/* {checkNesting(childUse?.children)} */}
                {checkNesting(children).props.props.children[step]}
              </StepperContent>
            )}

            <ButtonDiv flipButtons={flipButtons} position={buttonPosition}>
              {buttonPrevious && step > 0 && step !== stepperLabels?.length ? (
                <NextButton
                  flipButtons={flipButtons}
                  className={buttonClassName}
                  margin={buttonMargin}
                  padding={buttonPadding}
                  height={buttonHeight}
                  width={buttonWidth}
                  background={buttonBackground}
                  color={buttonTextColor}
                  borderRadius={buttonBorderRadius}
                  onClick={() => {
                    setStep(step - 1)
                  }}
                >
                  Previous
                </NextButton>
              ) : (
                ""
              )}
              {step !== stepperLabels?.length ? (
                <NextButton
                  className={buttonClassName}
                  margin={buttonMargin}
                  padding={buttonPadding}
                  height={buttonHeight}
                  width={buttonWidth}
                  background={buttonBackground}
                  color={buttonTextColor}
                  borderRadius={buttonBorderRadius}
                  onClick={() => {
                    setStep(step + 1)
                  }}
                >
                  {step === stepperLabels?.length - 1 ? "Submit" : "Next"}
                </NextButton>
              ) : (
                ""
              )}
            </ButtonDiv>
          </>
        </StepperDiv>
      </Suspense>
    </>
  )
}

export default StepperComponent
