// import React, { Suspense } from "react"

// const Cart = props => {
//   const isSSR = typeof window === "undefined"

//   const Cartty = React.lazy(() => import("./Cart"))
//   return (
//     <>
//       {!isSSR && (
//         <Suspense fallback={<div />}>
//           <Cartty props={props} />
//         </Suspense>
//       )}
//     </>
//   )
// }

// export default Cart

import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"
import { useSelector, useDispatch } from "react-redux"
import {
  setId,
  calculateTotals,
  clearCart,
  setUserCart,
} from "../../../../redux/slices/pages"
import styled from "styled-components"
import https from "../../../../services/https"
import { API_URL } from "../../../../primeagile.config"
import { Notify } from "../Notification/Notify"
import { Modal } from "react-bootstrap"
const CartItem = loadable(() => import("./CartItem"))

const PriceTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-weight: bold;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  margin: 4rem 0 2rem;
  font-size: 1rem;
  padding: 10px 0;

  p {
    margin: 0;
    font-size: 0.8rem;
  }

  h4 {
    font-size: 0.8rem;
    color: #333;
    font-weight: bold;
    margin: 0;
  }
`

const Header = styled.div`
  h2 {
    font-size: 1.1rem;
    color: #333;
    font-weight: 700;
  }
`

const ModalPrice = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;

  p, h4 {
    margin: 0;
  }

`

const Cart = props => {
  const isSSR = typeof window === "undefined"
  const location = typeof window !== "undefined" ? window.location : ""
  const [showmodal, setShow] = useState(false)
  const [responseData, setResponseData] = useState([])
  const updateCart = {
    name: 'update',
    Id: props?.pageData?.pageId
  }
  useEffect(() => {
    dispatch(setUserCart(updateCart))
  }, [])
  const handleClose = () => {
    setShow(false)
    dispatch(clearCart())

    let useNav = process.env.GATSBY_BUILDSITE === 'test.4dconceptsusa.com' ? '4dconceptsusa.com' : process.env.GATSBY_BUILDSITE 

    location.replace(`https://${useNav}`)
  }
  const handleShow = () => setShow(true)
  const show = true
  const dispatch = useDispatch()
  const { userCart, total,item } = useSelector(state => state.page)

  useEffect(() => {
    dispatch(setId(props?.pageData?.pageId))
  }, [props?.pageData?.pageId, dispatch])

  useEffect(() => {
    dispatch(calculateTotals())
  }, [userCart, dispatch])

  function fetchedSuccessfully(response) {
    // Notify({
    //   text: "Payment process successful.",
    //   type: "success",
    // })

    setResponseData([JSON.parse(response.config.data)])
    handleShow()
  }


  function failedToFetchInformation(error) {}
  useEffect(() => {
    const splited = location.href.includes("ssl_result_message=APPROVAL")

    if (splited) {
      let splitsplash = location.href?.split("/")[3]
      let formdata = splitsplash.split("&").reduce(function (obj, str, index) {
        let strParts = str?.split("=")
        if (strParts[0] && strParts[1]) {
          obj[strParts[0].replace(/\s+/g, "")] = strParts[1].trim()
        }
        return obj
      }, {})

      const link = `${API_URL}/open/commerce/order?client=${process.env.GATSBY_BUILDSITE}`

      let orderItemWList = []

      for (let [key, value] of Object.entries(userCart)) {
        let fprice = value.price?.slice(1, -1)
        orderItemWList = [
          ...orderItemWList,
          {
            // value,
            pageId: value.name,
            pageUrl: value.url,
            title: value.title,
            sku: value.sku,
            code: value.code,
            price: parseFloat(fprice)?.toFixed(2),
            quantity: value.quantity,
            color: value.color,
            image: value.image,
            shippingPrice: value.shipping,
            jsonData: {},
          },
        ]
      }
      const firstname = formdata.ssl_first_name
      const lastName = formdata.ssl_last_name
      const address = formdata.ssl_avs_address
      const state = formdata.ssl_state
      const city = formdata.ssl_city
      const cost = formdata.ssl_amount
      const email = formdata.ssl_email
      const country = formdata.ssl_country
      const confirmCode = formdata.ssl_txn_id
      const status = formdata.ssl_result_message
      const cardNumber = formdata.ssl_card_number
      const cardExpireDate = formdata.ssl_exp_date
      const confirmTime = formdata.ssl_txn_time
      const approvalCode = formdata.ssl_approval_code
      const shippingaddress1 = formdata.ssl_ship_to_address1
      const shippingFirstName = formdata.ssl_ship_to_first_name
      const shippingState = formdata.ssl_ship_to_state
      const shippingCountry = formdata.ssl_ship_to_country
      const transactionType = formdata.ssl_transaction_type
      const userZip = formdata.ssl_avs_zip
      let em = email?.split("%40")
      let first = em[0]
      let second = em[1]
      let usedEmail = first + "@" + second
      const items = {
        city,
        status,
        cardNumber,
        cardExpireDate,
        confirmTime,
        shippingaddress1,
        shippingFirstName,
        shippingState,
        shippingCountry,
        transactionType,
        address,
        state,
        country,
        email,
        userZip,
      }

      const data = {
        entityW: {
          isPerson: true,
          firstName: firstname,
          lastName: lastName,
          objectDataDefinitionId: 12,
          contactMethodWList:[
            {
              contactMethod: 4,
              contactMethodDetails: usedEmail,
              validation: '',
              isDefault: true,
            }
          ],
          active: true,
        },
        paymentW: {
          gatewayId: 1001,
          amount: cost,
          confirmationCode:  confirmCode,
          channel: 'CC',
        },
        orderItemWList,
        jsonData: {
          city: city,
          status: status,
          cardNumber: cardNumber,
          cardExpireDate: cardExpireDate,
          confirmTime: confirmTime,
          shippingaddress1: shippingaddress1,
          shippingFirstName: shippingFirstName,
          shippingState: shippingState,
          shippingCountry: shippingCountry,
          transactionType: transactionType,
          address: address,
          state: state,
          country: country,
          userZip: userZip,
        },
        status: 'NEW'
      }


    
      https(
        link,
        "post",
        data,
        undefined,
        fetchedSuccessfully,
        failedToFetchInformation
      )

      //! fire api to save records
    }
  }, [location.href])

  useEffect(() => {
    let timer

    if (showmodal) {
      document.body.style.overflow = "hidden"
      timer = setTimeout(() => {
        handleClose() 

      }, 3000)
    
    }
    return () => {
      document.body.style.overflow = "auto"
      clearTimeout(timer)
     
    }
  }, [showmodal])

  
  return (
    <>
    {!isSSR && (
      <>
      <Modal show={showmodal} onHide={handleClose} scrollable={true} size="lg">
        {responseData &&
          responseData.map((item, i) => (
            <div key={i}>
              <Modal.Header>
                <div>
                  <h3 style={{ fontSize: ".8rem !important" }}>
                    Thank you {item.entityW && item.entityW.firstName} {item.entityW && item.entityW.lastName},
                    your purchase was successful with Approval Code:
                    {item.paymentW && item.paymentW.confirmationCode}
                  </h3>
                </div>
              </Modal.Header>
              <div style={{ overflow: "auto", padding: "1rem", height: '75vh' }}>
                {item.orderItemWList &&
                  item.orderItemWList.map(
                    (order, i) => (
                      (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5rem",
                            gridGap: "5rem",
                            marginBottom: "1.3rem",
                          }}
                          key={i}
                        >
                          <div>
                            <img
                              src={order.image}
                              alt={order.title}
                              loading="lazy"
                              width={"250px"}
                            />
                          </div>
                          <div>
                            <h4>{order.title}</h4>
                            <span>
                              {order.quantity} x ${order.price}
                            </span>
                          </div>
                        </div>
                      )
                    )
                  )}
              </div>
              <ModalPrice>
                <p>Total Amount</p>
                <h4>${item.paymentW && item.paymentW.amount}</h4>
              </ModalPrice>
            </div>
          ))}
      </Modal>
      <section className="cart">
        <Header>
          <h2>
            {userCart?.length || 0} {userCart?.length <= 1 ? "item" : "Items"}{" "}
            in Cart
          </h2>
        </Header>
        <div>
          {userCart.length > 0 &&
            userCart.map(item => {
              if(item === null) return
              return (
                <CartItem
                  id={item.title}
                  img={item.image}
                  title={item.title}
                  price={item.price}
                  amount={item.amount}
                  quantity={item.quantity}
                  key={item.code}
                  name={item.name}
                  currency={item.currency}
                  shipping={item.shipping}
                />
              )
            })}
          <PriceTotal>
            {show && (
              <>
                <p>Cart SubTotal</p>
                <h4>${total && total?.toFixed(2)}</h4>
              </>
            )}
          </PriceTotal>
        </div>
      </section>
      </>
    )}
    </>
  )
}

export default Cart